<template>
  <a-modal
    v-if="show"
    :visible="show"
    destroy-on-close
    width="800px"
    :mask-closable="false"
    @cancel="emit('update:show', false)"
    :title="title"
  >
    <a-form v-if="record" class="myform" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
      <a-form-item label="Title">
        <a-input v-model:value="record.title" :disabled="readOnly"></a-input>
      </a-form-item>
      <a-form-item label="Code">
        <a-input :disabled="readOnly" v-model:value="record.code"></a-input>
      </a-form-item>
      <a-form-item label="Type">
        <a-select v-model:value="record.type" :disabled="readOnly">
          <a-select-option value="dashboard">dashboard</a-select-option>
          <a-select-option value="question">question</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="Id Embed">
        <a-input-number v-model:value="record.embed_id" :disabled="readOnly"></a-input-number>
      </a-form-item>

      <a-form-item label="Params">
        <a-table
          :data-source="params"
          size="small"
          :show-header="false"
          :pagination="false"
          :columns="[
            {
              dataIndex: 'key',
              title: 'Key',
              slots: {
                customRender: 'key',
              },
            },
            {
              dataIndex: 'value',
              title: 'Value',
              slots: {
                customRender: 'value',
              },
            },
            {
              title: 'Aksi',
              dataIndex: 'operation',
              slots: {
                customRender: 'operation',
              },
            },
          ]"
        >
          <template v-for="col in ['key', 'value']" #[col]="{  text, record }" :key="col">
            <div>
              <a-input
                v-if="editableData[record.key]"
                v-model:value="editableData[record.key][col]"
                style="margin: -5px 0"
              />
              <template v-else>
                {{ text }}
              </template>
            </div>
          </template>
          <template #operation="{ record, index }">
            <div class="editable-row-operations">
              <span v-if="editableData[record.key]">
                <a class="btn btn-sm" @click="save(record.key)">Save</a>
                <a class="btn btn-sm" @click="cancel(record.key)">Cancel</a>
              </span>
              <span v-else>
                <a v-if="!readOnly" class="btn btn-sm" @click="edit(record.key)">Edit</a>
                <a v-if="!readOnly" class="btn btn-sm" @click="remove(index)">Hapus</a>
              </span>
            </div>
          </template>
        </a-table>
        <a-button v-if="!readOnly" @click="handleAdd">Add Params</a-button>
      </a-form-item>
    </a-form>

    <template #footer>
      <a-button key="submit" :loading="isSubmit" @click="submit" v-if="!readOnly" type="primary"
        >Simpan</a-button
      >
      <a-button key="cancel" @click="emit('update:show', false)">Cancel</a-button>
    </template>
  </a-modal>
</template>

<script>
import { cloneDeep } from 'lodash-es'
import { reactive, toRefs, ref, watch } from 'vue'
import apiClient from '@/services/axios'
import { message } from 'ant-design-vue'

export default {
  components: {},
  props: {
    show: [Boolean],
    id: { type: [Number, Object], default: null },
    readOnly: [Boolean],
    title: { type: [String], default: null },
  },
  emits: ['update:show', 'submitted', 'update:id'],
  setup(props, { emit }) {
    const isSubmit = ref(false)
    const showState = reactive({ title: '' })
    const params = ref([])
    const record = ref({
      params: [],
    })

    const submit = e => {
      if (record.value.id === null || record.value.id === undefined) {
        isSubmit.value = true
        apiClient
          .post('/api/metabase', { ...record.value })
          .then(response => {
            message.success('Berhasil ditambah')
            record.value = {}
            params.value = []
            emit('submitted')
            emit('update:show', false)
          })
          .catch(error => {
            console.log(error.response.data)
            if (error.response && error.response.data) {
              message.error(error.response.data[0].message)
            }
          })
          .finally(() => {
            isSubmit.value = false
          })
      } else {
        isSubmit.value = true
        apiClient
          .post('/api/metabase/' + record.value.id + '?_method=PUT', { ...record.value })
          .then(response => {
            message.success('Berhasil diubah')
            record.value = {}
            params.value = []
            emit('submitted')
            emit('update:show', false)
          })
          .catch(error => {
            console.log(error.response.data)
            if (error.response && error.response.data) {
              message.error(error.response.data[0].message)
            }
          })
          .finally(() => {
            isSubmit.value = false
          })
      }
    }

    watch(
      () => props.id,
      val => {
        if (val === null) {
          record.value = {}
          params.value = []
          return
        }
        apiClient
          .get('/api/metabase/' + val)
          .then(response => {
            record.value = response.data
            params.value = record.value.params
              ? Object.keys(record.value.params).map(k => {
                  return { key: k, value: record.value.params[k] }
                })
              : []
          })
          .finally(() => {})
      },
    )

    watch(
      () => props.show,
      val => {
        if (!val) {
          emit('update:id', null)
          return
        }
      },
    )

    const editableData = reactive({})

    const edit = key => {
      editableData[key] = cloneDeep(params.value.filter(item => key === item.key)[0])
    }

    const convertArrayToObject = (array, key) => {
      const initialValue = {}
      return array.reduce((obj, item) => {
        return {
          ...obj,
          [item[key]]: item['value'],
        }
      }, initialValue)
    }

    const save = key => {
      Object.assign(params.value.filter(item => key === item.key)[0], editableData[key])
      delete editableData[key]
      record.value.params = convertArrayToObject(params.value, 'key')
    }

    const cancel = key => {
      delete editableData[key]
    }

    const remove = key => {
      params.value.splice(key, 1)
      record.value.params = convertArrayToObject(params.value, 'key')
    }

    const handleAdd = () => {
      if (params.value.findIndex(v => v.key === null) === -1) {
        params.value.push({ key: null, value: null })
      }
    }

    return {
      visible: true,
      submit,
      cancel,
      ...toRefs(showState),
      emit,
      params,
      editableData,
      edit,
      save,
      remove,
      record,
      handleAdd,
      isSubmit,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
