<template>
  <div>
    <!-- tombol  -->
    <div class="row justify-content-end m-2">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <a-button type="primary" title="Tambah Data" @click="add">
          <i class="fa fa-plus" aria-hidden="true"></i>
        </a-button>
      </div>
    </div>
    <md-table
      :columns="columns"
      :data-source="items"
      row-key="id"
      :pagination="{
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
        showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
        total: meta.totalCount,
        pageSize: meta.perPage,
        current: meta.currentPage,
      }"
      arow-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
      arow-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      @change="handleTableChange"
      :loading="loading"
    >
      <template #no="{ index }">
        <span>
          {{ startRow + index }}
        </span>
      </template>
      <template #action="{record}">
        <div class="d-flex align-items-center ">
          <a-tooltip title="Lihat data">
            <a
              href="javascript: void(0);"
              class="btn btn-sm mx-1 btn-outline-primary"
              @click="view(record)"
            >
              <small>
                <i class="fe fe-eye" />
              </small>
            </a>
          </a-tooltip>
          <a-tooltip title="Ubah data">
            <a
              href="javascript: void(0);"
              class="btn btn-sm mx-1 btn-outline-primary"
              @click="edit(record)"
            >
              <small>
                <i class="fe fe-edit" />
              </small>
            </a>
          </a-tooltip>
          <a-tooltip title="Hapus data">
            <a
              href="javascript: void(0);"
              class="btn btn-sm mx-1 btn-outline-danger"
              @click="hapus(record)"
            >
              <small>
                <i class="fe fe-trash" />
              </small>
            </a>
          </a-tooltip>
        </div>
      </template>
    </md-table>
    <!-- <pre>{{ { items, ...meta } }}</pre> -->
    <m-modal
      v-model:show="show"
      v-model:id="id"
      v-model:readOnly="readOnly"
      v-model:title="title"
      @submitted="submitted"
      @update:show="submitted"
    ></m-modal>
  </div>
</template>

<script>
import { toRef, provide, toRefs, ref, reactive, createVNode, onMounted } from 'vue'
import { useMetabase } from './api'
import MModal from './modal'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { Modal, message } from 'ant-design-vue'

import apiClient from '@/services/axios'

const columns = [
  {
    title: 'No',
    slots: { customRender: 'no' },
  },
  {
    title: 'Title',
    dataIndex: 'title',
  },
  {
    title: 'Kode',
    dataIndex: 'code',
  },
  {
    title: 'Tipe',
    dataIndex: 'type',
  },
  {
    title: 'Embed Id',
    dataIndex: 'embed_id',
  },
  {
    title: 'Aksi',
    slots: { customRender: 'action' },
  },
]

export default {
  components: {
    MModal,
  },
  setup() {
    const { _meta: meta, items, loading, refresh } = useMetabase()
    const startRow = ref(1)
    onMounted(() => {
      refresh({ is_deleted: false })
    })
    const handleTableChange = (pag, filters, sorter) => {
      const page = pag.current
      const perPage = pag.pageSize
      refresh({ page, 'per-page': perPage, is_deleted: false })
      startRow.value = (page - 1) * perPage + 1
    }

    // state
    const state = reactive({
      readOnly: false,
      show: false,
      is: 'new',
      record: {},
      id: null,
      title: '',
    })

    const view = record => {
      state.show = true
      state.readOnly = true
      state.id = record.id
      state.record = record
      state.title = 'Lihat Metabase'
    }

    const add = () => {
      state.show = true
      state.id = null
      state.is = 'new'
      state.record = {}
      state.readOnly = false
      state.title = 'Tambah Metabase'
    }

    const edit = record => {
      state.record = record
      state.id = record.id
      state.is = 'edit'
      state.show = true
      state.readOnly = false
      state.title = 'Ubah Metabase'
    }

    const hapus = record => {
      Modal.confirm({
        title: 'Konfirmasi',
        icon: createVNode(ExclamationCircleOutlined),
        content: 'Apakah anda yakin hapus data ini?',

        onOk() {
          apiClient
            .delete('/api/metabase/' + record.id)
            .then(response => {
              message.success('Berhasil dihapus')
              refresh()
            })
            .catch(error => {
              if (error.response && error.response.data) {
                message.error(error.response.data[0].message)
              }
            })
            .finally(() => {})
        },

        onCancel() {
          console.log('Cancel')
        },
      })
    }

    const submitted = show => {
      if (show === undefined) refresh()
      state.record = {}
      state.id = null
      state.readOnly = false
      state.title = ''
      state.is = ''
    }

    return {
      items,
      meta,
      loading,
      columns,
      selectedRowKeys: [],
      onSelectChange: () => {},
      handleTableChange,
      startRow,
      add,
      edit,
      view,
      ...toRefs(state),
      submitted,
      hapus,
    }
  },
}
</script>

<style></style>
