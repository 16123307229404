import apiClient from '@/services/axios'
import { reactive, toRefs } from 'vue'

export function useMetabase() {
  const metabases = reactive({ items: [], _meta: {}, loading: false })

  const refresh = (params = {}) => {
    metabases.loading = true
    apiClient
      .get('/api/metabase', { params })
      .then(response => {
        metabases.items = response.data.items
        metabases._meta = response.data._meta
      })
      .finally(() => {
        metabases.loading = false
      })
  }

  return { ...toRefs(metabases), refresh }
}
